import React, {Fragment, useEffect} from "react";
import {useQuery, useMutation} from "@apollo/client";
import {useAsyncSetState} from "use-async-setstate";
import Formsy from "formsy-react";
import {Alert, Button, Modal, Container, Row, Col} from "react-bootstrap";
import copy from "copy-to-clipboard";

import Input from "../bootstrap/input";
import Captcha from "../bootstrap/captcha";
import FieldTemplate from "../bootstrap/form-group";
import {
  getPageInfoQuery,
  getEwayClientKey,
} from "../logic/general";
import {
  createMobileVerificationMutation,
  createMobileVerificationResult,
  resendVerificationMutation,
} from "../logic/mobile";
import {
  purchaseAndCreateUserMutation,
  getPurchaseResult,
} from "../logic/purchase";
import {Link} from "gatsby";
import Review from "../review";
import {getFormData, clearFormData} from "../manager/form";
import debounce from "debounce";


export default function ProductPurchase({building, product, onSuccess}) {
  const [userData, setUserData] = useAsyncSetState(false);
  const [isResend, setResend] = useAsyncSetState(false);
  const [isProcessing, setProcessing] = useAsyncSetState(false);
  const [showSuccessModal, setShowSuccessModal] = useAsyncSetState(false);
  const [showReview, setReview] = useAsyncSetState(false);
  const [showVerification, setVerification] = useAsyncSetState(false);
  const [credentials, setCredentials] = useAsyncSetState(null);
  const [purchaseError, setPurchaseError] = useAsyncSetState(null);
  const [captchaError, setCaptchaError] = useAsyncSetState(null);
  const [verificationCode, setVerificationCode] = useAsyncSetState(null);
  const [captchaRefresh, setCaptchaRefresh] = useAsyncSetState(0);

  const [cmvMutation] = useMutation(createMobileVerificationMutation);
  const [rmvMutation] = useMutation(resendVerificationMutation);
  const [purchaseMutation] = useMutation(purchaseAndCreateUserMutation);

  const formData = getFormData();
  const {unitNo, streetNo, address, suburb, postCode, state, countryId, country} = formData;
  const {
    voucherCode,
    selectedProducts,
  } = formData || {};

  const variables = {
    buildingCode: building?.code,
    voucherCode,
  };
  const pageInfoQuery = useQuery(getPageInfoQuery, { variables });
  if (pageInfoQuery.loading) {
    return <Fragment />
  };
  const ewayKey = getEwayClientKey(pageInfoQuery);

  function handleCopyPassword(password) {
    return copy(password, {
      debug: true,
      message: "Press #{key} to copy",
    });
  }

  async function showMobileVerification({ mobile, captcha, captchaKey }) {
    try {
      await setProcessing(true);
      const response = await cmvMutation({
        variables: {
          mobileNumber: mobile,
          captcha: captcha,
          captchaKey: captchaKey,
        },
        awaitRefetchQueries: true,
      });

      const { errors } = response;
      if (errors) {
        await setProcessing(false);
        if ((errors || [])[0]?.message === "Invalid Captcha Key" || (errors || [])[0]?.message === "Invalid Captcha") {
          console.log("gotta refresh captcha");
          await setCaptchaRefresh(captchaRefresh + 1);
        }
        return setCaptchaError((errors || [])[0]?.message || "");
      }

      if (createMobileVerificationResult(response)) {
        await setVerification(true);
        setCaptchaError(null);
        return setProcessing(false);
      } else {
        //TODO: present failure message
        await setProcessing(false);
      }
    } catch (err) {
      console.log({ err });
      if (err.length) {
        await setProcessing(false);
        return setCaptchaError(err[0].message);
      }

      await setProcessing(false);
      return setCaptchaError("Invalid captcha");
    }
    await setProcessing(false);
  }

  async function handleValidSubmit(code) {
    await setProcessing(true);
    await setVerification(false);
    await setVerificationCode(code);
    await setReview(true);
    await setProcessing(false);
  }

  function handleCloseModal() {
    return setVerification(false);
  }

  
  const submitRequest = debounce(async ({userData, purchaseMutation, payData}) => {
    await setProcessing(true);
    
    try {
      const data = {
        firstName: payData.firstName,
        lastName: payData.lastName,
        email: userData.email,
        mobileNumber: payData.mobile,
        voucherCode,
        verificationCode: verificationCode,
        streetNo,
        address,
        suburb,
        postCode,
        state,
        building: unitNo,
        countryId,
        unitNo,
        country,
        buildingCode: "",
        products: selectedProducts.map((p) => ({id: p.id, ...p.orderItemOpts})),
        cardName: payData.cardHolderName,
        cardNumber: window.eCrypt.encryptValue(payData.cardNumber, ewayKey),
        cardCCV: window.eCrypt.encryptValue(payData.cardCCV, ewayKey),
        cardExpiry: `${payData.cardExpiryMonth}${payData.cardExpiryYear}`,
      };

      const response = await purchaseMutation({
        variables: {
          ...data,
        },
      });

      // const errArray = {
      //   EBUILDING: "Building not found",
      //   EBUILDINGADDRESS: "Building address not found",
      //   EVOUCHER: "Invalid voucher",
      //   ESERVICE: "Service Unavailable",
      //   EACTIVE: "Service is active",
      //   EVERIFICATIONCODE: "Invalid verification code",
      //   EINVALIDPLANS: "Invalid Plans",
      //   EUNKNOWN: "An error has occured",
      //   "Email is already registered": "Email is already registered",
      //   "Invalid EWAY_CARDNUMBER": "Invalid card number",
      //   proper_email: "Please use a proper email",
      //   "Invalid captcha code provided": "Invalid captcha code provided",
      // };
      // const { errors } = response;
      // if (errors) {
      //   let errorMessage = errors[0]?.message || errors;
      //   await setProcessing(false);
      //   if (errArray[errorMessage]) {
      //     errorMessage = errArray[errorMessage];
      //   }
      //   return setPurchaseError(errorMessage);
      // }

      const result = getPurchaseResult(response);

      if ((result || {}).id) {
        await setProcessing(false);
        await setReview(false);
        await setCredentials(result);
        await setUserData(null);
        await setPurchaseError(null);
        clearFormData();
        return setShowSuccessModal(true);
      }
      await setProcessing(false);
      return setPurchaseError("An error has occured");
    } catch (err) {
      const errArray = {
        EBUILDING: "Building not found",
        EBUILDINGADDRESS: "Building address not found",
        EVOUCHER: "Invalid voucher",
        ESERVICE: "Service Unavailable",
        EACTIVE: "Service is active",
        EVERIFICATIONCODE: "Invalid verification code",
        EINVALIDPLANS: "Invalid Plans",
        EUNKNOWN: "An error has occured",
        "Email is already registered": "Email is already registered",
        "Invalid EWAY_CARDNUMBER": "Invalid card number",
        proper_email: "Please use a proper email",
        "Invalid captcha code provided": "Invalid captcha code provided",
      };

      let errorMessage = err.message.replace(/(GraphQL error:)/gi, "") || err.message;
      if (errArray[errorMessage]) {
        errorMessage = errArray[errorMessage];
      }
      await setProcessing(false);
      return setPurchaseError(errorMessage || "An error has occured");
    }
  }, 1000);
  const handlePurchase = () => {
    const data = getFormData();
    setProcessing(true);
    return submitRequest({userData: data, purchaseMutation, payData: userData});
  }

  return (
    <>
      {captchaError && (
        <Modal centered show onHide={() => setCaptchaError(null)}>
          <Modal.Header>
            <Modal.Title>{"An error has occured"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container fluid>
              <Row>
                <Col>{captchaError}</Col>
              </Row>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="darkblue"
              onClick={() => setCaptchaError(null)}>
              {"Close"}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {showReview && (
        <>
          {purchaseError && (
            <Modal show onHide={() => setPurchaseError(null)}>
              <Modal.Header>
                <Modal.Title>{"An error has occured"}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Container fluid>
                  <Row>
                    <Col>{purchaseError}</Col>
                  </Row>
                </Container>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="darkblue"
                  onClick={() => setPurchaseError(null)}>
                  {"Close"}
                </Button>
              </Modal.Footer>
            </Modal>
          )}
          <Review
            product={product}
            submit={async () => {
              return await handlePurchase();
            }}
            onClose={async () => setReview(false)}
            isProcessing={isProcessing}
            purchaseError={purchaseError}
            {...userData}
          />
        </>
      )}
      {showSuccessModal && (
        <Modal
          show
          onHide={async () => {
            await setShowSuccessModal(false);
          }}>
          <Modal.Header bsPrefix="no-close-header" closeButton={false}>
            <Modal.Title>{"Purchase Success!"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container fluid>
              <Row className="my-2">
                <Col xs={3}>{"Order ID: "}</Col>
                <Col className="credential-value">{credentials.id}</Col>
              </Row>
              <Row className="my-2">
                <Col xs={3}>{"Username"}</Col>
                <Col className="credential-value">{credentials.userName}</Col>
              </Row>
              <Row className="my-2">
                <Col xs={3}>{"Password"}</Col>
                <Col className="credential-value">
                  {credentials?.newPassword}
                  <Button
                    variant="vw-button px-1 py-0 ml-1"
                    onClick={() => {
                      handleCopyPassword(credentials?.newPassword);
                    }}>
                    <i className="fas fa-copy" />
                  </Button>
                </Col>
              </Row>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Link className="btn btn-darkblue" to="/login">
              {"Back to Login"}
            </Link>
          </Modal.Footer>
        </Modal>
      )}
      {showVerification ? (
        <>
          {purchaseError && (
            <Modal centered show onHide={() => setPurchaseError(null)}>
              <Modal.Header>
                <Modal.Title>{"An error has occured"}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Container fluid>
                  <Row>
                    <Col>{purchaseError}</Col>
                  </Row>
                </Container>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="darkblue"
                  onClick={() => setPurchaseError(null)}>
                  {"Close"}
                </Button>
              </Modal.Footer>
            </Modal>
          )}
          <Modal show onHide={handleCloseModal}>
            <Formsy
              onValidSubmit={async (data) => {
                return handleValidSubmit(data.code);
              }}
              onInvalidSubmit={(e) => {
                console.log("error", e);
              }}>
              <Modal.Header closeButton>
                <Modal.Title>{"Confirm your mobile number"}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Container fluid>
                  <Row>
                    <Col>
                      <div className="alert alert-primary">
                        {
                          "We have sent a text message to your phone, please enter the code below"
                        }
                      </div>
                    </Col>
                  </Row>
                  <Row className="mt-20">
                    <Col>
                      <Input
                        name="code"
                        label="Mobile Verification Code"
                        defaultValue=""
                        disabled={isProcessing || purchaseError !== null}
                        required
                      />
                    </Col>
                  </Row>
                </Container>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary vw-button"
                  disabled={isResend || isProcessing || purchaseError !== null}
                  onClick={async () => {
                    await setResend(true);
                    await rmvMutation({
                      variables: {
                        mobileNumber: userData.mobile,
                      },
                    });

                    return setTimeout(() => {
                      return setResend(false);
                    }, 2000);
                  }}
                >
                  {isResend ? "Success" : "Resend"}
                </Button>
                <Button
                  type="submit"
                  disabled={isProcessing || purchaseError !== null}
                  variant="darkblue"
                >
                  {isProcessing ? "Please wait ... " : "Submit"}
                </Button>
              </Modal.Footer>
            </Formsy>
          </Modal>
        </>
      ) : undefined}
      <Formsy
        onValidSubmit={async (input) => {
          console.log("INPUT >>> ", input);
          await setUserData(input);
          await showMobileVerification(input);
        }}
        onInvalidSubmit={(e) => {
          console.log("error", e);
        }}>
        <Container className="mt-3">
          <Row>
            <Col>
              <div className="purchase-title main-text text-center font-white my-5">
                {"Enter your details to purchase"}
              </div>
            </Col>
          </Row>

          {(selectedProducts || []).some(({type}) => type === "hardware")&& (
            <>
              <Row>
                <Col>
                  <div className="font-white">{"Shipping Address"}</div>
                  <hr className="white-divider" />
                </Col>
              </Row>
              <Row>
                <FieldTemplate md={2}>
                  <Input
                    name="unitNoShipping"
                    placeholder="Unit No"
                    label="Unit No"
                    defaultValue={unitNo}
                  />
                </FieldTemplate>
                <FieldTemplate md={2}>
                  <Input
                    name="streetNoShipping"
                    label="Street No"
                    placeholder="Street Number"
                    defaultValue=""
                    value={`${streetNo || ""}`}
                  />
                </FieldTemplate>
                <FieldTemplate md={8}>
                  <Input
                    name="addressShipping"
                    label="Street Name"
                    placeholder="Street Name"
                    defaultValue=""
                    value={`${address || ""}`}
                  />
                </FieldTemplate>
              </Row>
              <Row>
                <FieldTemplate md={3}>
                  <Input
                    name="suburbShipping"
                    type="text"
                    label="City"
                    placeholder="City"
                    value={`${suburb}`}
                  />
                </FieldTemplate>
                <FieldTemplate md={3}>
                  <Input
                    name="stateShipping"
                    type="text"
                    label="State"
                    placeholder="State"
                    value={`${state}`}
                  />
                </FieldTemplate>
                <FieldTemplate md={3}>
                  <Input
                    name="countryShipping"
                    type="text"
                    label="Country"
                    placeholder="Country"
                    value={`${country}`}
                    disabled
                  />
                </FieldTemplate>
                <FieldTemplate md={3}>
                  <Input
                    name="postCodeShipping"
                    type="text"
                    label="Postal Code"
                    placeholder="Postal Code"
                    value={`${postCode}`}
                  />
                </FieldTemplate>
              </Row>
              <Row>
                <Col>
                  <hr className="white-divider" />
                </Col>
              </Row>
            </>
          )}
          <Row>
            <FieldTemplate>
              <Input
                name="firstName"
                label="First Name"
                placeholder="First Name"
                defaultValue=""
                required
              />
            </FieldTemplate>
            <FieldTemplate>
              <Input
                name="lastName"
                label="Last Name"
                placeholder="Last Name"
                defaultValue=""
                required
              />
            </FieldTemplate>
          </Row>

          <Row>
            <FieldTemplate md={3}>
              <Input
                name="unitNo"
                placeholder="Unit No"
                label="Unit No"
                defaultValue={unitNo}
                disabled
              />
            </FieldTemplate>
            <FieldTemplate md={9}>
              <Input
                name="streetNo"
                label="Street"
                placeholder="Street"
                defaultValue=""
                disabled
                value={`${streetNo} ${address}`}
              />
            </FieldTemplate>
          </Row>
          <Row>
            <FieldTemplate md={3}>
              <Input
                name="suburb"
                type="text"
                label="City"
                placeholder="City"
                value={`${suburb}`}
                disabled
              />
            </FieldTemplate>
            <FieldTemplate md={3}>
              <Input
                name="state"
                type="text"
                label="State"
                placeholder="State"
                value={`${state}`}
                disabled
              />
            </FieldTemplate>
            <FieldTemplate md={3}>
              <Input
                name="country"
                type="text"
                label="Country"
                placeholder="Country"
                value={`${country}`}
                disabled
              />
            </FieldTemplate>
            <FieldTemplate md={3}>
              <Input
                name="postCode"
                type="text"
                label="Postal Code"
                placeholder="Postal Code"
                value={`${postCode}`}
                disabled
              />
            </FieldTemplate>
          </Row>
          <Row>
            <FieldTemplate>
              <Input
                type="email"
                name="email"
                label="Email Address"
                placeholder="Email Address"
                validations={{
                  isEmail: true,
                  minLength: 1,
                }}
                validationErrors={{
                  isEmail: "Email address is not valid",
                  minLength: "",
                }}
                defaultValue=""
                required
              />
            </FieldTemplate>
            <FieldTemplate>
              <Input
                name="mobile"
                label="Mobile Number"
                placeholder="Mobile Number"
                type="number"
                defaultValue=""
                required
              />
            </FieldTemplate>
          </Row>

          <Row>
            <FieldTemplate>
              <Input
                name="cardHolderName"
                label="Card Holder Name"
                placeholder="Card Holder Name"
                validations={{
                  maxLength: 50,
                  isExisty: true,
                  matchRegexp: /^[a-zA-Z-,]+(\s{0,1}[a-zA-Z-, ])*$/,
                }}
                validationErrors={{
                  isExisty: "This field is required",
                  maxLength: "You can not type in more than 50 characters",
                  matchRegexp: "Enter the name on your credit card",
                }}
                defaultValue=""
                required
              />
            </FieldTemplate>
            <FieldTemplate>
              <Input
                name="cardNumber"
                label="Card Number"
                placeholder="Card Number"
                validations={{
                  maxLength: 16,
                  minLength: 16,
                  isNumeric: true,
                  isExisty: true,
                }}
                validationErrors={{
                  isNumeric: "You can only enter in numerical characters",
                  isExisty: "This field is required",
                  maxLength: "You can not type in more than 16 characters",
                  minLength: "You can not type in less than 16 characters",
                }}
                defaultValue=""
                required
              />
            </FieldTemplate>
          </Row>

          <Row>
            <FieldTemplate md={4}>
              <Input
                name="cardExpiryMonth"
                label="Card Expiry Month"
                placeholder="Card Expiry Month"
                validations={{
                  maxLength: 2,
                  minLength: 2,
                  isNumeric: true,
                  isExisty: true,
                  invalidMonthValue: (__, value) => {
                    return value <= 12 && value > 0; // Checks if valid month
                  },
                  isExpired: (values, value) => {
                    const currentMonth = Number(new Date()
                      .getMonth()
                      .toString()
                      .slice(-2)) + 1;
                    const currentYear = new Date()
                      .getFullYear()
                      .toString()
                      .slice(-2);

                    return (
                      // Validates if card is expired
                      values.cardExpiryYear === undefined ||
                      values.cardExpiryYear.toString().trim() === "" ||
                      Number(values.cardExpiryYear) > Number(currentYear) ||
                      (Number(
                        currentMonth > 10 ? `0${currentMonth}` : currentMonth
                      ) <= Number(value)) && Number(values.cardExpiryYear) >= Number(currentYear)
                    );
                  },
                }}
                validationErrors={{
                  minLength: "You can not type in less than 2 characters",
                  maxLength: "You can not type in more than 2 characters",
                  isNumeric: "You can only enter in numerical characters",
                  isExisty: "This field is required",
                  invalidMonthValue: "Cannot input invalid month",
                  isExpired: "Card already expired",
                }}
                defaultValue=""
                required
              />
            </FieldTemplate>
            <FieldTemplate md={4}>
              <Input
                name="cardExpiryYear"
                label="Card Expiry Year"
                placeholder="Card Expiry Year"
                validations={{
                  maxLength: 2,
                  minLength: 2,
                  isNumeric: true,
                  isExisty: true,
                }}
                validationErrors={{
                  minLength: "You can not type in less than 2 characters",
                  maxLength: "You can not type in more than 2 characters",
                  isNumeric: "You can only enter in numerical characters",
                  isExisty: "This field is required",
                }}
                defaultValue=""
                required
              />
            </FieldTemplate>
            <FieldTemplate md={4}>
              <Input
                name="cardCCV"
                label="CCV"
                placeholder="CCV"
                defaultValue=""
                validations={{
                  minLength: 3,
                  maxLength: 3,
                  isExisty: true,
                  isNumeric: true,
                }}
                validationErrors={{
                  isNumeric: "You can only enter in numerical characters",
                  isExisty: "CCV field is required",
                  minLength: "You can not type in less than 3 characters",
                  maxLength: "You can not type in more than 2 characters",
                }}
                required
              />
            </FieldTemplate>
          </Row>
          <Row>
            <Col xs="6" className="ml-auto">
              <Captcha key={captchaRefresh} inline />
            </Col>
          </Row>
          <Row className="mt-2 mb-2">
            <Col xs="auto" className="ml-auto">
              <Button type="submit" variant="orange" disabled={isProcessing}>
                {"Purchase"}
              </Button>
            </Col>
          </Row>
        </Container>
      </Formsy>
    </>
  );
}
